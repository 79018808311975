import { createSelector, select } from '@ngrx/store';
import { pipe } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { selectWorkshopData } from './workshop.selectors';
import { selectVehicles } from './vehicles.selectors';
import { selectUser } from './user.selectors';

export const selectAllMaintenances = createSelector(
  selectVehicles,
  vehicles => vehicles?.map(vehicle => vehicle.maintenances).flat()
);

export const selectAllMaintenancesIds = createSelector(
  selectVehicles,
  vehicles => vehicles?.map(vehicle => vehicle.maintenancesIds).flat()
);

export const selectVehicleMaintenances = (vehicleId) => createSelector(
  selectVehicles,
  vehicles => vehicles?.find(v => v.id === vehicleId)?.maintenances
);

export const selectMaintenance = (entityId) => createSelector(
  selectAllMaintenances,
  selectWorkshopData,
  (maintenances, workshopData) => {
    const maintenance = maintenances.find(m => m.id === entityId);
    const verifiedMaintenance = workshopData?.verifiedMaintenances.find(m => m.id === entityId);
    return (maintenance || verifiedMaintenance);
  }
);

export const selectValidMaintenances = pipe(
  select(selectAllMaintenances),
  filter(maintenances => !!maintenances),
);

export const selectTotalMaintenances = pipe(
  select(selectAllMaintenances),
  filter(maintenances => !!maintenances),
  map(maintenances => maintenances.length)
);

export const selectValidTransferredVehiclesMaintenances = pipe(
  select(selectUser),
  filter(user => !!user),
  map(user => user.transferredVehicles.reduce((acc, curr) => curr.maintenances.length ? [...acc, ...curr.maintenances] : acc, [])),
);
